@charset 'UTF-8';

// wrapper
// -------
.global-navigation {
  position: relative;
}

// navigation
// ----------


// valiables
// @use _page.scss

// side navigation width
$side_navigation_width: 29rem;

// transition delay time
$menu_transition_time: .3s;




//
nav.side_navigation_wrapper {
  background: #f5f4e9;
  backface-visibility: hidden;
  border-right: solid 1px #ccc;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: $side_navigation_width;
  transform: translateX( - $side_navigation_width );
  transition: transform $menu_transition_time;
  z-index: 5;
  @include media(lg) {
    transition: none;
  }
  // メニューオープン
  #{$menu_open_class} & {
    transform: translateX(0);
  }

  @include media(lg) {
    transform: translateX(0);
  }
}


// parent ul
.side_navigation {
  margin: 0;

  & > li {
    border-bottom: 1px solid $gray-300;

    & > a {
      color: #333;
      display: block;
      padding: 1.2em 1em;
      position: relative;
      &:before {
        content: '';
        border-left: 6px solid $second-color;
        margin-right: 1em;
      }
    }
    &.is_child_menu {
      & > a {
        &:after {
          font-family: $icon-font-family;
          content: '\f0d7';
          color: $gray-600;
          font-weight: 900;
          display: inline-block;
          position: absolute;
          top: 50%;
          right: 1em;
          transform: translateY(-50%);
        }
      }
    }
  }
}

// child menu
.side_navigation_child {
  display: none;
  border-bottom: none;

  & > li {
    border-top: 1px solid $gray-300;
    position: relative;
    &:before {
      font-family: $icon-font-family;
      content: map-get( $icon-type, 'arrows' );
      display: inline-block;
      font-weight: 900;
      color: $base-color;
      position: absolute;
      left: 1.5em;
      top: 50%;
      transform: translateY(-50%);
      @include media(md) {

      }
    }

    & > a {
      color: #111;
      display: block;
      font-size: 85%;
      padding: 1.2em 1em 1.2em 3.5em;
      background: darken(rgba($base-color, 0.1), 5%);
    }
  }
}



// メニュー上部のロゴ表示
.side_navigation_logo {
  display: none;
  padding: 2.5em 1em;

  & > a {
    padding: 0;

    & > img {
      width: 100%;
    }
  }

  @include media(lg) {
    display: block;
  }
}


// nav 内のバナーエリア
.banner-area {
  width: 200px;
  margin: 2em auto;

  li {
    margin-bottom: 1em;
    text-align: center;
  }
}


// メニューオープン時に body に付く class
.side_menu_open {
  position: relative;
  overflow: hidden;
}
