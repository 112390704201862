@charset 'UTF-8';

// 変数
// ---

$burger-border-color: #fff; // burger のカラー
$burger-border-thickness: 2px; // burger の太さ
// burger の長さ
$burger-border-width: (
  'sm': 24px,
  'md': 28px,
  'lg': 36px
);
// burger 同士の間隔
$burger-border-margin: (
  'sm': 4px,
  'md': 6px,
  'lg': 10px
);

/**
*
* @used _global-navigation.scss
* @used _page.scss
*
*/
$menu_open_class: '.side_menu_open'; // burger open 時に付与するクラス名


// Burger
// ------
.burger_wrapper {
  background: darken( $base-color, 20% );
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: map-unit( $height-header, sm, rem );
  height: map-unit( $height-header, sm, rem );
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  @include media(md) {
    width: map-unit( $height-header, md, rem );
    height: map-unit( $height-header, md, rem );
  }
  @include media(lg) {
    display: none;
  }
}


.burger-icon {
  display: block;
  width: map-get( $burger-border-width, 'sm' );
  span {
    background: $burger-border-color;
    display: block;
    width: 100%;
    height: $burger-border-thickness; // 太さ
    transition: all .4s;
    &:nth-of-type(2) {
      margin: map-get( $burger-border-margin, 'sm' ) 0; // ボーダー同士の間隔
    }
  }
  @include media(md) {
    width: map-get( $burger-border-width, 'md' );
    span:nth-of-type(2) {
      margin: map-get( $burger-border-margin, 'md' ) 0; // ボーダー同士の間隔
    }
  }
  @include media(lg) {
    display: none;
  }
}

// OPEN
#{$menu_open_class} .burger-icon {
  //background: transparent;
  span {
    &:nth-of-type(1) {
      transform: translateY(6px) rotate(-45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(-6px) rotate(45deg);
    }
  }
  @include media(md) {
    span {
      &:nth-of-type(1) {
        transform: translateY(8px) rotate(-45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translateY(-8px) rotate(45deg);
      }
    }
  }
  @include media(lg) {
    span {
      &:nth-of-type(1) {
        transform: translateY(12px) rotate(-45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translateY(-12px) rotate(45deg);
      }
    }
  }
}
