@charset 'UTF-8';

$button-size-padding-tb: map-zip( $font-size-list, $button-size-padding-tb-array ); // map-zip( $keys, $values ) キーのリスト、値のリストの 2 つから新しい map を生成して返す

// button
// ------

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  &:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
  }
}

.button {
  background: multi-array( $button-color, 'base', 'bg' );
  border: $button-border solid transparent;
  border-radius: $button-border-radius;
  color: multi-array( $button-color, 'base', 'color' );
  display: inline-block;
  font-size: 1em;
  line-height: 1.42875 !important;
  text-align: center;
  -webkit-appearance: none;
  text-decoration: none;
  transition: background $transition, color $transition;
  vertical-align: middle;
  padding: map-unit( $button-padding-tb, sm ) map-unit( $button-padding-lr, sm );
  @include media(md) {
    padding: map-unit( $button-padding-tb, md ) map-unit( $button-padding-lr, md );
  }
  @include media(lg) {
    padding: map-unit( $button-padding-tb, lg ) map-unit( $button-padding-lr, lg );
  }
  // Hover
  &:hover {
    background: multi-array( $button-hover-color, 'base', 'bg' );
    color: multi-array( $button-hover-color, 'base', 'color' );
    text-decoration: none;
  }
  // Color Pattern
  @each $name, $value in $button-color {
    &.#{$name} {
      background: map-get( $value, 'bg' );
      border: $button-border solid transparent;
      color: map-get( $value, 'color' );
      &:hover {
        background: multi-array( $button-hover-color, #{$name}, 'bg' );
        color: multi-array( $button-hover-color, #{$name}, 'color' );
      }
    }
  }
  // 文字サイズによる padding-top & padding-bottom の上書き
  @each $list in $font-size-list {
    &.#{$list} {
      padding: map-get( $button-size-padding-tb, $list );
    }
  }
} // .button


// Outline
// -------

.button.outline {
  background: multi-array( $button-outline-color, 'base', 'bg' );
  border: $button-border solid multi-array( $button-outline-color, 'base', 'border' );
  color: multi-array( $button-outline-color, 'base', 'color' );
  text-shadow: none;
  // Hover
  &:hover {
    background: multi-array( $button-outline-hover-color, 'base', 'bg' );
    color: multi-array( $button-outline-hover-color, 'base', 'color' );
    text-decoration: none;
  }
  // Color Pattern
  @each $name, $value in $button-outline-color {
    &.#{$name} {
      background: map-get( $value, 'bg' );
      border: $button-border solid map-get( $value, 'border' );
      color: map-get( $value, 'color' );
      &:hover {
        background: multi-array( $button-outline-hover-color, #{$name}, 'bg' );
        color: multi-array( $button-outline-hover-color, #{$name}, 'color' );
      }
    }
  }
}


// Expanded
// --------

.button.expanded {
  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}


// button icon
// -----------

.button,
.button.outline {
  & span {
    &::after {
      @include icon-default;
      margin-left: .6em;
    }
    &.left {
      &::after { content: none; }
      &::before {
        @include icon-default;
        margin-right: .6em;
      }
    }
    // icon-type
    @each $name, $icon in $icon-type {
      &.#{$name} {
        &::after {
          content: $icon !important;
        }
        &.left  {
          &::after {
            content: none !important;
          }
          &::before {
            content: $icon !important;
          }
        }
      }
    }
    // icon color
    @each $name, $color in $color-pattern {
      &.ic-#{$name} {
        &::after, &::before {
          color: $color;
        }
      }
    }
  } // span
}

.button.notice{
  background: #fff;
  border: solid 2px $red-color;
  color: $red-color;
  &:hover{
    background: $red-color;
    color: #fff;
  }
}