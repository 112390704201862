@charset 'UTF-8';

// header
// ------

header {
  background: transparent;
  padding-bottom: map-unit( $header-padding-bottom, sm );
  position: fixed;
  width: 100%;
  z-index: map-unit($z-index, header);
  @include media(md) {
    padding-bottom: map-unit( $header-padding-bottom, md );
  }
  @include media(lg) {
    padding-bottom: map-unit( $header-padding-bottom, lg );
    position: static;
  }
}

.header-wrap {
  background: $background-header;
  height: map-unit( $height-header, sm, rem );
  position: relative;
  padding: 0 map-unit( $height-header, sm, rem );
  @include media(md) {
    height: map-unit( $height-header, md, rem );
    padding: 0 map-unit( $height-header, md, rem );
  }
  @include media(lg) {
    height: map-unit( $height-header, lg, rem );
    padding: 0;
  }
}

.header-title {
  width: 100%;
  height: 100%;
  position: relative;
  //position: absolute;
  //top: 50%;
  //left: 50%;
  //transform: translate( -50%, -50% );
  @include media(lg) {

  }
  a{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
    display: block;
    width: 32rem;
    max-width: 100%;
    padding: 1em;
    @include media(md) {
      width: 32rem;
    }
    @include media(lg) {
      position: absolute;
      top: 50%;
      left: auto;
      right: 1em;
      transform: translate( 0, -50% );
      width: 40rem;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}

.phone-trigger {
  border-left: solid 1px #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: map-unit( $height-header, sm, rem );
  height: map-unit( $height-header, sm, rem );
  padding: 1rem;

  span {
    color: #fff;
    display: block;
    font-size: 2em;
  }

  @include media(md) {
    width: map-unit( $height-header, md, rem );
    height: map-unit( $height-header, md, rem );
  }
  @include media(lg) {
    display: none;
  }
}

//電話番号選択
.modal-wrapper{
  position: absolute;
  top: map-unit( $height-header, sm, rem );;
  width: 100%;
  transform: translateY(-100%);
  z-index: 3;
  transition: 0.25s;
  @include media(lg) {
    display: none;
  }
  &.visible{
    transform: translateY(0);
  }
  .modal-container{
    background: #f5f4e9;
    width: 100%;
    padding: 2em;
    //電話番号ボタン
    .call-btn{
      background: $base-color;
      padding: 0.5em;
      width: 100%;
      display: block;
      text-align: center;
      color: #fff;
      border-radius: 0.25em;
      &.second{
        background: $second-color;
      }
      &:hover{
        color: #fff;
      }
      & .call-text{
        display: block;
        font-size: 1.5em;
        font-weight: bold;
        &::before{
          font-family: $icon-font-family;
          content: '\f3cd';
          margin-right: 0.5em;
        }
      }//.call-text
    }//.call-btn
  }//.modal-container
}//.modal-wrapper

.modal_open{
  overflow: hidden;
}

.side_menu_open main::before,
.modal_open main::before{
  content: '';
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  display: block;
  position: absolute;
  background: rgba(#333, 0.5);
  z-index: 2;
  @include media(lg) {
    display: none;
  }
}