@charset 'UTF-8';

// all pages
// ---------

// 全体
.base_contents_wrapper {
  position: relative;
  width: 100%;
}

// メインコンテンツ
.main_contents_wrapper {
  backface-visibility: hidden;
  //transform: translateX(0);
  -webkit-overflow-scrolling: touch;
  transition: transform $menu_transition_time;
  //overflow: hidden;
  @include media(lg) {
    margin-left: $side_navigation_width;
  }

  // メニューオープン時にコンテンツを動かす
  #{$menu_open_class} & {
    transform: translateX( $side_navigation_width );
  }
}

// TOP page
// --------

// Slider
.slider-wrapper {
  width: 100%;
  img {
    width: 100%;
  }
}

// トピックス
.top_topics {
  a {
    img {
      transition: box-shadow .3s;
    }
    &:hover img {
      box-shadow: 0 0 9px 2px #ccc;
    }
  }
}

// 新着情報
.top_news_list {
  font-size: 1.125em;
  margin-bottom: 2rem;
  li {
    padding: 1rem 0;
    border-bottom: 1px dotted $fourth-color;
    @include media(lg) {
      padding: 1.6rem 0;
    }
    &:first-child{
      padding-top: 0;
    }
    dl {
      line-height: 1.6;
      dt {
        display: block;
        padding-bottom: 0.25em;
        color: $second-color;
      }
    }
  }
}

// SUB pages
// ---------

// head visual
.sub-visual {
  background: url( '/images/sub/visual_sub.jpg' ) 0 0 no-repeat;
  background-size: 100% auto;
  background-position: center;
  flex: none;
  padding-bottom: calc( 100% * 300 / 1920 );
  position: relative;
  @include media(lg) {
    padding-bottom: calc( 100% * 200 / 1920 );
  }
  &::before{
    background-color: rgba( #000, .3 );
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .sub-text {
    color: #fff;
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1.5;
    position: absolute;
    top: 50%;
    left: 0;
    padding-left: 1em;
    transform: translate(0, -50%);
    text-align: left;
    text-shadow: 0 0 8px #000;
    @include media(lg) {
      font-size: 1.8em;
      padding-left: 3rem;
    }
  }
}

//lightboxの調整
.lb-image{
  width: 100%;
  @include media(md) {
    width: 76rem !important;
    max-width: 76rem !important;
    height: auto !important;
    max-height: auto !important;
  }
}

a[href*="tel"]{
  color: #000;
  text-decoration: none;
  @include media(md) {
    pointer-events: none;
  }
}

.flow{
  .flow-content{
    border: solid 1px $second-color;
    padding: 1em;
    background: #fff;
    position: relative;
    text-align: center;
    & + .flow-content{
      margin-top: 5em;
      &::before{
        line-height: 1;
        position: absolute;
        top: -1.5em;
        left: 50%;
        font-family: $icon-font-family;
        color: $second-color;
        content: '\f13a';
        font-weight: 900;
        font-size: 2.5em;
        transform: translateX(-50%);
      }
    }
    .flow-header{
      font-weight: bold;
      font-size: 1.25em;
      //color: $second-color,;
      background: rgba($second-color, 0.2);
      padding: 0.5em 0.25em;
      & + .flow-body{
        padding-top: 1em;
      }
    }
    
  }
}

.map{
  height: 400px;
  border: solid 1px #ccc;
  @include media(md) {
    height: 600px;
  }
}

.border{
  border: solid 1px #ccc;
}

//年間行事
.event-wrap{
  .event-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    .event-month{
      font-size: 1.75em;
      font-weight: bold;
    }
    .event-name{
      font-size: 1.25em;
    }
    .event-img{
      width: 20%;
    }
    & + .event-content{
      border-top: dotted 2px #ccc;
    }
  }
}