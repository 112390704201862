@charset 'UTF-8';

.tab-label {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 4px solid $second-color;
  justify-content: space-between;
  & > li {
    position: relative;
    transition: $transition;
    width: 100%;
    &:nth-child(n+2) {
      margin-left: .5em;
    }
    a {
      color: $second-color;
      background: $gray-200;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      display: block;
      padding: .8rem 1.2rem;
      transition: background $transition;
      font-weight: bold;
      font-size: 1.125em;
      text-align: center;
      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
      &:not(.active):hover {
        background: rgba($second-color, 0.5);
        color: #fff;
        outline: none;
      }
    }
    &.current a {
      //border-bottom: 4px solid $base-color;
      background: $second-color;
      color: #fff;
      &:hover {
        background: $second-color;
      }
    }
  }
}

.tab-content {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 1em;
  background: #fff;
  @include media(md) {
    padding: 2em;
  }
}
