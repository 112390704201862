@charset 'UTF-8';

// Block
// -----

// block の border の太さ
$block-border: 2px;

// block の border-radius
$block-border-radius: 5px;

// block の box-shadow
$block-box-shadow: 0 2px 4px 0 rgba( 0,0,0, .12 );

// color
// -----
// background-color, color
$block-color: (
  'base'   : ( 'bg' : $base-color,   'color' : #fff ),
  'first'  : ( 'bg' : $first-color,  'color' : #fff ),
  'second' : ( 'bg' : $second-color, 'color' : #fff ),
  'third'  : ( 'bg' : $third-color,  'color' : #fff ),
  'fourth' : ( 'bg' : $fourth-color, 'color' : #fff ),
  'five'   : ( 'bg' : $five-color,   'color' : #fff )
) !default;
